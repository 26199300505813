import { BaseResponse } from '../models/rest/baseResponse';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

interface ICallCenterJobResponse extends BaseResponse {
  CallCenterJob: ICallCenterJob;
}

export interface ICallCenterJob {
  Id?: number;
  Name: string;
  Period: CallCenterJobsFrequencyType;
  CronExpression: string;
  StartHour: number;
  IsActive: boolean;
  Type: CallCenterJobsReportType;
}

export function getNewCallCenterJobModel(): ICallCenterJob {
  return {
    Name: '',
    Period: 0,
    CronExpression: '',
    StartHour: 0,
    IsActive: false,
    Type: 0,
  };
}

export enum CallCenterJobsFrequencyType {
  Daily,
  Weekly,
  Monthly
}

export enum CallCenterJobsReportType {
  Summary,
  Location,
}

export function getCallCenterJobFrequencyTypeList() {
  return [
    {
      Id: CallCenterJobsFrequencyType.Daily,
      Name: CallCenterJobsFrequencyType[CallCenterJobsFrequencyType.Daily],
    },
    {
      Id: CallCenterJobsFrequencyType.Weekly,
      Name: CallCenterJobsFrequencyType[CallCenterJobsFrequencyType.Weekly],
    },
    {
      Id: CallCenterJobsFrequencyType.Monthly,
      Name: CallCenterJobsFrequencyType[CallCenterJobsFrequencyType.Monthly],
    }
  ];
}

export function getCallCenterJobReportTypeList() {
  return [
    {
      Id: CallCenterJobsReportType.Summary,
      Name: CallCenterJobsReportType[CallCenterJobsReportType.Summary],
    },
    {
      Id: CallCenterJobsReportType.Location,
      Name: CallCenterJobsReportType[CallCenterJobsReportType.Location],
    }
  ];
}

export function getHoursView(): string[] {
  return ['00:00 AM', '01:00 AM', '02:00 AM', '03:00 AM', '04:00 AM', '05:00 AM',
    '06:00 AM', '07:00 AM', '08:00 AM', '09:00 AM', '10:00 AM', '11:00 AM',
    '12:00 AM', '01:00 PM', '02:00 PM', '03:00 PM', '04:00 PM', '05:00 PM',
    '06:00 PM', '07:00 PM', '08:00 PM', '09:00 PM', '10:00 PM', '11:00 PM'];
}

export function getSetDayOfMonth(): number[] {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
    11, 12, 13, 14, 15, 16, 17, 18, 19,
    20, 21, 22, 23, 24, 25, 26, 27];
}

export interface DayOfWeek {
  Name: string;
  Id: string | number;
}
export function getSetDayOfWeek(): DayOfWeek[] {
  return [
    {Name: 'Sunday', Id: 0}, {Name: 'Monday', Id: 1},
    {Name: 'Tuesday', Id: 2}, {Name: 'Wednesday', Id: 3},
    {Name: 'Thursday', Id: 4}, {Name: 'Friday', Id: 5},
    {Name: 'Saturday', Id: 6},
  ];
}

@Injectable({ providedIn: 'root' })
export class CallCenterJobsService {
  url = 'CallCenter/Jobs';
  constructor(private _httpClient: HttpClient) {
  }

  getAll(): Observable<ICallCenterJob[]> {
    return this._httpClient.get<ICallCenterJob[]>(this.url);
  }

  create(digest: ICallCenterJob): Observable<any> {
    return this._httpClient.post<ICallCenterJobResponse>(this.url, { CallCenterJob: digest });
  }

  update(digest: ICallCenterJob): Observable<any> {
    return this._httpClient.put<ICallCenterJob>(this.url, { CallCenterJob: digest });
  }

  delete(Id: number): Observable<any> {
    return this._httpClient.delete<ICallCenterJob>(this.url + `/${Id}`);
  }


  public parseCronExpressionToDay(cron: string): string {
    if (!cron) {
      return '';
    }
    let cronArray = cron.split(' ');
    if (!cronArray || cronArray.length < 3) {
      return '';
    }
    let day = cronArray[3];
    return day === '*' || day === '?' ? '-' : day;
  }

  public parseCronExpressionToWeek(cron: string): string {
    if (!cron) {
      return '';
    }
    let cronArray = cron.split(' ');
    if (!cronArray || cronArray.length < 5) {
      return '';
    }
    let week = cronArray[5];
    return week === '*' || week === '?' ? '-' : week;
  }

  public convertStartHourNumberForTimeView(startHour: number): string {
    if (!startHour) {
      return '00:00 AM';
    }
    let USATimeTicker = '';
    if (startHour < 12) {
      USATimeTicker = 'AM';
    } else {
      USATimeTicker = 'PM';
    }

    return `${startHour}:00 ${USATimeTicker}`;
  }

  public convertWeekDayNumberForWeekView(weekDayNumber: number): string {
    switch (weekDayNumber) {
      case 0:
        return 'Sunday';
      case 1:
        return 'Monday';
      case 2:
        return 'Tuesday';
      case 3:
        return 'Wednesday';
      case 4:
        return 'Thursday';
      case 5:
        return 'Friday';
      case 6:
        return 'Saturday';
      default:
        return '';
        break;
    }
  }
}
